<template>
  <div class="product-card">
    <v-icon
      class="product-card__fav cursor-pointer"
      size="20px"
      color="error"
      :key="favTogglerKey"
      @click="toggleFavHandler"
      >mdi-heart{{ item.isFav ? "" : "-outline" }}</v-icon
    >
    <div class="not-active-message" v-if="!item.is_active">
      <span>{{ $t("global.noProduct") }}</span>
    </div>
    <div :class="item.is_active ? '' : 'opacity-3'">
      <div
        class="card__tag mx-auto d-flex align-center justify-center text-center"
        v-if="item.tag"
        :class="item.tag.shape == 'circle' ? 'card__tag__circle' : ''"
        :style="{ background: item.tag.background }"
      >
        <span class="font-35" :style="{ color: item.tag.color }">{{
          item.tag.name
        }}</span>
      </div>

      <div
        class="relative product-card__img cursor-pointer"
        @click="openHandler"
      >
        <img :key="item.name" :src="item.image" />
        <div class="product-card__serial">
          <span class="font-16">{{ item.serial }}</span>
        </div>
      </div>

      <div
        class="product-card__content px-2 py-2 d-flex align-center justify-space-between"
      >
        <span
          class="d-block font-14 font-500"
          style="width: 200px"
          v-if="item.name"
          >{{ item.name }}
        </span>
        <div>
          <span
            class="d-inline-block font-14 line-discount"
            v-if="item.has_discount"
            >{{ item.regularPrice }}</span
          >
          <span class="d-inline-block font-18 mx-2 primary--text font-600"
            >{{
              item.has_discount ? item.discountPrice : item.regularPrice
            }}
            TL</span
          >
        </div>
      </div>

      <div class="d-flex align-center justify-space-between mb-4 mx-2">
        <div>
          <span
            class="font-14 primary--text"
            v-if="item.note && item.note.slice(0, 33)"
            >{{ item.note }}
            <span v-if="item.note.length > 34">...</span>
          </span>
        </div>
        <div :key="resetCount">
          <base-counter
            :value="item.count"
            @changeCount="changeCountHandler"
          ></base-counter>
        </div>
      </div>

      <div class="product-card__btns d-flex align-center justify-space-between">
        <div
          class="product-card__btns__btn d-flex align-center text-center justify-center"
          @click="sendRequest"
          :class="settings[8].value != '0' ? '' : 'opacity-3 cursor-stop'"
        >
          <span class="white--text font-14">{{ $t("global.request") }}</span>
        </div>
        <!-- line -->
        <div class="product-card__line"></div>

        <!-- sparis -->
        <div
          class="product-card__btns__btn d-flex align-center text-center justify-center"
          @click="addHandler"
        >
          <span class="white--text font-14 d-block">{{
            $t("global.addToCart")
          }}</span>
        </div>
      </div>

      <v-dialog
        v-model="productDialog"
        max-width="1100px"
        :content-class="`white     ${
          isMd ? 'radius-10 pa-2' : 'radius-20 pa-5'
        }'`"
        :key="resetDialog + productDialog"
      >
        <product-details
          @close="productDialog = false"
          @exit="exitHandler"
          :item="selectedItem"
          :requestType="requestType"
        ></product-details>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ProductDetails from "@/components/dialogs/ProductDetails.vue";
import { fetchProduct } from "@/services/Products";
import { mapGetters } from "vuex";
export default {
  components: { ProductDetails },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    productDialog: false,
    resetDialog: false,
    resetCount: false,
    favTogglerKey: false,
    requestType: "",
    selectedItem: {},
    favList: [],
  }),
  computed: {
    ...mapGetters(["locationDetails", "settings"]),
    locationID() {
      return localStorage.getItem("locationID");
    },
    language() {
      return localStorage.getItem("language");
    },
  },
  methods: {
    exitHandler() {
      this.resetDialog = !this.resetDialog;
      this.productDialog = false;
      this.$emit("reRender");
    },
    changeCountHandler(val) {
      this.item.count = val;
    },
    async openHandler() {
      if (!this.item.is_active) {
        return;
      }
      this.selectedItem = await fetchProduct(this.item.id, this.locationID);
      this.selectedItem.count = this.item.count;
      if (this.selectedItem.id) {
        this.productDialog = true;
      }
    },
    addHandler() {
      this.item.price = this.item.has_discount
        ? this.item.discountPrice
        : this.item.regularPrice;

      let cartList = [];

      let storageList = JSON.parse(localStorage.getItem("cartList"));
      if (storageList) {
        cartList = storageList;
      }

      this.item.images = [];
      this.item.images[0] = this.item.relationships.image;

      cartList.push(this.item);
      localStorage.setItem("cartList", JSON.stringify(cartList));
      this.$store.dispatch("renderCart");
      this.$store.dispatch("setCartList", cartList);
      this.$store.dispatch("showSnack", {
        text: this.$t("global.itemAddedToCart"),
        color: "success",
      });
      setTimeout(() => {
        this.resetCount = !this.resetCount;
        this.item.count = 1;
      }, 100);
    },
    toggleFavHandler() {
      this.favList = JSON.parse(localStorage.getItem("favList")) || [];
      let isFavProduct = this.favList.filter(
        (product) => product.id == this.item.id
      );

      // clone the list
      let newList = this.favList;
      if (isFavProduct.length > 0) {
        this.item.isFav = false;
        newList = this.favList.filter((product) => product.id != this.item.id);
      } else {
        this.item.isFav = true;
        newList.push(this.item);
      }
      this.favTogglerKey = !this.favTogglerKey;
      localStorage.setItem("favList", JSON.stringify(newList));
    },
    checkActive() {
      this.favList = JSON.parse(localStorage.getItem("favList")) || [];
      this.favList.forEach((product) => {
        if (product.id == this.item.id) {
          this.item.isFav = true;
        }
      });
    },
    async sendRequest() {
      if (this.settings[8].value == "0") return;
      if (this.item.regularPrice * +this.item.count < +this.settings[9].value) {
        this.$store.dispatch("showSnack", {
          text: this.$t("global.orderLimit") + this.settings[9].value,
          color: "error",
        });
        return;
      }
      let whatsappMessage = "";
      let cartList = [this.item];

      cartList.forEach((item) => {
        whatsappMessage += `*${item.serial}* - ${item.name} ${+item.count} * ${
          item.has_discount ? item.discountPrice : item.regularPrice
        } = ${
          +item.count *
          +(item.has_discount ? item.discountPrice : item.regularPrice)
        }  %0a`;
      });
      whatsappMessage += "%0a";

      whatsappMessage += `${
        this.language == "ar"
          ? "ـــــــــــــــــــــــــــــ"
          : "---------------------"
      }`;

      whatsappMessage += `%0a`;
      whatsappMessage += `image_link `;

      let req = {
        products: cartList.map((item) => {
          return {
            product_id: item.serial,
            name: item.name,
            price: +(item.has_discount
              ? item.discountPrice
              : item.regularPrice),
            amount: +item.count,
            total:
              +item.count *
              +(item.has_discount ? item.discountPrice : item.regularPrice),
          };
        }),
        is_delivery: 0,
        general_total_price:
          +this.item.count *
          +(this.item.has_discount
            ? this.item.discountPrice
            : this.item.regularPrice),
        coupon_value: "",
        total_price:
          +this.item.count *
          +(this.item.has_discount
            ? this.item.discountPrice
            : this.item.regularPrice),
        products_amount: +this.item.count,
      };

      this.$axios.defaults.baseURL = "https://api.master-chicken.com/v1/";
      let res = await this.$axios.get(
        "frontend/checkout",
        {
          params: {
            invoice_data: JSON.stringify(req),
            location_id: this.locationID,
            message: whatsappMessage,
            location_phone: this.locationDetails.phone,
            lang: this.language,
          },
        },
        {
          headers: {
            lang: this.language,
          },
        }
      );
      this.$axios.defaults.baseURL = "https://api.master-chicken.com/api/v1/";
      if (res.request.status == 200) {
        window.open(res.request.responseURL, "_open");
        localStorage.setItem("cartList", JSON.stringify([]));
        this.$store.dispatch("renderCart");
        this.$store.dispatch("setCartList", []);
      }
    },
  },
  created() {
    this.checkActive();
  },
};
</script>

<style lang="scss">
.product-card {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: unset;
  position: relative;

  &__fav {
    position: absolute !important;
    top: 0px;
    right: 8px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    z-index: 99;
  }

  &__serial {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 6px;
    border-radius: 4px;
    opacity: 0.7;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: rgb(243, 243, 243);
  }
  &__img {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    background: white;
  }

  &__btns {
    display: flex;

    &__btn {
      width: 50%;
      background: rgb(180, 55, 55);
      padding: 10px 0px;
      cursor: pointer;
      transition: 0.3s;
      border-right: 1px solid rgba(255, 255, 255, 0.3);

      &:last-child {
        border-right: 0px;
      }

      &:hover {
        background: rgb(235, 99, 99);
      }
    }
  }
  .product-card__line {
    width: 1px;
    height: 100%;
    background: rgb(255, 255, 255);
  }
}
</style>
