<template>
  <div class="favorite mb-14">
    <div class="max-width" :key="'render' + reRender">
      <div class="text-center d-flex justify-space-between align-center mb-10">
        <div
          class="d-inline-flex align-center cursor-pointer"
          @click="$router.push('/category')"
        >
          <v-icon>mdi-chevron-{{ language == "ar" ? "right" : "left" }}</v-icon>
          <span class="mx-2">{{ $t("global.backToProducts") }}</span>
        </div>
        <span class="underline font-32 font-600 d-inline-block">
          {{ $t("global.yourFavoriteList") }}
        </span>
        <div class="px-14 py-1 mx-8" v-if="!isMd"></div>
      </div>
      <v-row v-if="items.length > 0">
        <v-col
          class="relative"
          cols="6"
          sm="4"
          md="3"
          v-for="(item, i) in items"
          :key="i"
        >
        <product-card
                @reRender="rerenderHandler(item)"
                :key="reRenderItem"
                :item="item"
              ></product-card>
        </v-col>
      </v-row>
      <div class="text-center my-4" v-if="items.length == 0">
        <span class="font-20 font-500"
          >{{ $t("global.favoriteEmpty") }}
          <span
            class="cursor-pointer primary--text font-600"
            @click="$router.push('/category')"
            >{{ $t("global.seeProducts") }}</span
          >
        </span>
      </div>
    </div>

    <v-dialog
      v-model="productDialog"
      max-width="1100px"
      :content-class="`white     ${
        isMd ? 'radius-10 pa-2' : 'radius-20 pa-5'
      }'`"
      :key="resetDialog"
    >
      <product-details
        @exit="exitHandler"
        @close="productDialog = false"
        :item="selectedItem"
      ></product-details>
    </v-dialog>
  </div>
</template>

<script>
import ProductDetails from "@/components/dialogs/ProductDetails.vue";
import { fetchProduct } from "@/services/Products";
import ProductCard from '../products/components/ProductCard.vue';

export default {
  components: { ProductDetails,ProductCard },
  data: () => ({
    reRender: false,
    resetDialog: false,
    items: JSON.parse(localStorage.getItem("favList")) || [],
    productDialog: false,
    reRenderItem: false,
    selectedItem: {},
  }),
  computed: {
    locationID() {
      return localStorage.getItem("locationID");
    },
    language() {
      return localStorage.getItem("language");
    },
  },
  methods: {
    rerenderHandler(item){
      item.count = 1
      this.reRenderItem = !this.reRenderItem
    },
    exitHandler() {
      this.resetDialog = !this.resetDialog;
      this.productDialog = false;
    },
    removeItem(item) {
      this.items = this.items.filter((product) => product.id != item.id);
      localStorage.setItem("favList", JSON.stringify(this.items));

      this.reRender = !this.reRender;
    },
    async getProduct(item) {
      this.selectedItem = await fetchProduct(item.id, this.locationID);
      if (this.selectedItem.id) {
        this.productDialog = true;
      }
    },
    async getData() {
      let res = await this.$axios.get(
        `frontend/products_without_img?location_id=${this.locationID}`
      );
      if (res.data) {
        res.data.forEach((product) => {
          this.items.forEach((favProduct) => {
            if (favProduct.id == product.id) {
              favProduct.price = product.has_discount
                ? product.discountPrice
                : product.regularPrice;
              favProduct.is_active = product.is_active;
            }
          });
        });

        this.items = this.items.filter((item) =>
          res.data.some((product) => item.id == product.id)
        );
        localStorage.setItem("favList", JSON.stringify(this.items));
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.favorite {
  margin: 60px 0px;
  &__item {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .remove-item {
    z-index: 99;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
  }
}
.product-card {
  &__serial {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 6px;
    border-radius: 4px;
    opacity: 0.7;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: rgb(243, 243, 243);
  }
  &__img {
    width: 100%;
    height: 200px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
